// -------- Flow on Homepage -------- //
.flowbox {
    display: flex;
    flex-direction: column;

    .bzfy-o-container {
        padding: 0;

        .bzfy-feed-banner {
            padding: 20px 0 !important; // source code is from flowbox
        }

        .slick-list {
            margin-left: -12px;
            margin-right: -12px;
        }
    }
}

#flowbox__home:empty ~ a:first-of-type {
    // hides flow if there is no content loaded in
    display: none;
}

// -------- Flow on PDP -------- //
.pdp__top-details {
    display: flex;
    flex-direction: column;

    .hide-for-medium-down {
        order: -1;
    }
}

.pdp__top-details #flowbox__pdp:empty {
    // hides flow if there is no content loaded in
    ~ div nav ul li:first-of-type {
        display: none;
    }

    ~ .flowbox__button-container a {
        display: none;
    }

    ~ hr:first-of-type {
        display: none;
    }
}
