@include breakpoint(small only) {
    .grid-header .tile::before {
        padding-top: 100% !important;
    }
}

.front-page-grid {

    --tile-padding: 15px;

    @include breakpoint(medium down) {
        --tile-padding: 10px;
    }

    margin: 0 calc(-1 * var(--tile-padding));

    @function one-thirds() {
        @return 1/3 * 100%;
    }

    @function two-thirds() {
        @return 2/3 * 100%;
    }

    display: flex;

    @include breakpoint(small only) {
        display: block;
    }

    .tile {
        position: relative;
        padding: var(--tile-padding);
        background-clip: content-box;
        background-color: var(--color-white);
        flex: 0 0 auto;

        @include breakpoint(small only) {
            width: 100% !important;
        }
    }

    // Small screen ratios:

    $small-screen-ratios: (
            half: 0.5,
            double: 2
    );

    @each $ratio-alias, $multiplier in $small-screen-ratios {

        %small-height-#{$ratio-alias} {
            @include breakpoint(small only) {
                &::before {
                    display: block;
                    content: '';
                    width: 100%;
                    padding-top: $multiplier * 100%;
                }
            }
        }

        %always-height-#{$ratio-alias} {
            &::before {
                display: block;
                content: '';
                width: 100%;
                padding-top: $multiplier * 100%;
            }
        }

    }

    %square {
        &::before {
            display: block;
            content: '';
            width: 100%;
            padding-top: 100%;
        }
    }

    &.square-tiny--1-by-4,
    &.horizontal-medium--square-tiny--square-tiny,
    &.square-tiny--square-tiny--horizontal-medium {

        .horizontal-medium {
            width: 50%;
            @extend %small-height-half;
        }

        .square-tiny {
            @extend %square;
            width: 25%;
        }
    }

    &.square-very-tiny--1-by-6 {
        .square-very-tiny {
            @extend %square;
            width: 16.6%;
        }
    }

    &.square-medium--square-medium {

        .tile {
            width: 50%;
            @extend %square;
        }
    }

    &.horizontal-large--square-small,
    &.square-small--horizontal-large,
    &.square-small-horizontal-large
    {

        .horizontal-large {
            width: two-thirds();
            @extend %small-height-half;
        }

        .square-small {
            @extend %square;
            width: one-thirds();
        }
    }

    &.square-large--vertical-small,
    &.vertical-small--square-large {

        .square-large {
            @extend %square;
            width: two-thirds();
        }

        .vertical-small {
            width: one-thirds();
            @extend %small-height-double;
        }
    }

    &.square-medium--square-tiny--square-tiny--horizontal-medium,
    &.square-tiny--square-tiny--horizontal-medium--square-medium {

        .square-medium {
            @extend %square;
            width: 50%;
        }

        .tilewrap {
            width: 50%;

            @include breakpoint(small only) {
                width: 100%;
            }

            .tiny-squares,
            .horizontal-medium {
                height: 50%;
            }

            .tiny-squares {

                display: flex;

                @include breakpoint(small only) {
                    display: block;
                }

                .square-tiny { // tile
                    @extend %square;
                    width: 50%;
                }
            }

            .horizontal-medium {
                @extend %always-height-half;
            }
        }
    }

    &.square-small--square-small--square-small {
        .tile {
            width: one-thirds();
            @extend %square;
        }
    }

    .banner_tile {
        width: 100%;

        .slide {
            background-size: contain;
            background-repeat: no-repeat;
            @include breakpoint(small only) {
                background-size: cover;
            }
            color: var(--color-black);
        }

        &::before {
            display: block;
            content: '';
            padding-top: 37%;

            @include breakpoint(small only) {
                padding-top: 100% !important;
            }
        }
    }
}

//// Frontpage grid tile nesting hierarchy:

.front-page-grid {

    .front-page-block {
        position: absolute;

        top: var(--tile-padding);
        right: var(--tile-padding);
        bottom: var(--tile-padding);
        left: var(--tile-padding);
    }

    .block-content,
    .slider {
        width: 100%;
        height: 100%;
    }

    .slick-list {
        position: relative;
        height: 100%;

    }

    .slick-track {
        height: 100%;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        z-index: 1;

        transition: opacity .2s ease-out;

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;
        }
    }

    .slide {
        width: 100%;
        height: 100%;
        color: var(--color-black);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // Do not 'optimize' background-properties!
        background-color: var(--color-white);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &--top {
            background-position: top;

            img {
                object-position: top;
            }
        }

        &--center {
            background-position: center;

            img {
                object-position: center;
            }
        }

        &--bottom {
            background-position: bottom;

            img {
                object-position: bottom;
            }
        }

        .banner-media-block {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;

            &__content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                box-sizing: border-box;
                padding-bottom: rem-calc(18);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                gap: 6px;
                color: var(--color-grayscale-9);
                width: 90%;
                margin: 0 auto;

                @include breakpoint(medium down) {
                    padding-bottom: 16px;
                }

                &.white {
                    color: var(--color-white);
                }
            }

            &__title {
                font-family: var(--font-family-primary);
                font-size: clamp(24px, 3vw, 32px);
                font-weight: var(--font-weight-bold);
                color: inherit;
                margin: 0;
                line-height: 1.2;
                letter-spacing: rem-calc(2);

                @include breakpoint(medium down) {
                    line-height: initial;
                }
            }

            &__small_text {
                line-height: rem-calc(15);
                color: inherit;
                margin-bottom: 8px;
                display: none;
                font-size: rem-calc(15);
                font-weight: normal;

                @include breakpoint(large) {
                    display: block;
                }
            }

            .button {
                padding: 0 24px;

                @include breakpoint(medium up) {
                    padding: 0 30px;
                }

                &.dark {
                    color: var(--color-white);
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: rem-calc(20);

        display: block;

        width: 100%;
        padding: 0;

        list-style: none;

        text-align: right;

        li {
            position: relative;

            display: inline-block;

            width: rem-calc(14);
            height: rem-calc(14);
            margin: 0 rem-calc(5);


            &:last-child {
                margin-right: rem-calc(20);
            }

            padding: 0;

            cursor: pointer;

            button {
                font-size: 0;
                line-height: 0;

                display: block;
                padding: 0;
                width: rem-calc(14);
                height: rem-calc(14);
                box-sizing: border-box;
                border-radius: 50%;
                background-color: var(--color-white);
                border: 1px solid var(--color-grayscale-2);
                cursor: pointer;
                color: transparent;
                outline: none;
                opacity: 1;
                transition: border-color .2s ease-out;


                &:hover,
                &:focus
                {
                    outline: none;
                    border-color: var(--color-cement);
                }
            }

            &.slick-active {
                button {
                    border-color: var(--color-cement);
                }
            }
        }
    }
}

$content-layout-block-bottom-margin-desktop: 4.3rem;
$content-layout-block-bottom-margin-mobile: 3rem;

.front-page-inspirations-container {
    background: var(--color-grayscale-10);
    padding-bottom: $content-layout-block-bottom-margin-desktop;

    @media only screen and (max-width: 640px) {
        padding-bottom: $content-layout-block-bottom-margin-mobile;
    }
}

.front-page-inspirations {
    padding: 0;

    .column.title {
        border-top: 1px solid var(--color-grayscale-2);
    }

    .header-image {
        width: 100%;
    }

    h2 {
        text-align: center;
        margin: 47px 0 32px 0;
    }

    h3 {
        text-align: left;
        margin: 47px 0 32px 0;
    }

    .related-articles-container {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;

        &.row {
            &:after {
                content: none;
            }
        }
    }

    .tile {
        margin: 0 15px;
        display: flex;
        flex-direction: column;
        background: var(--color-white);

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
            margin-bottom: 0;
        }

        .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 2rem 1rem 2rem;

            .title {
                margin: 20px 0 5px;
                word-wrap: break-word;
                font-size: 18px;
                @include line-clamp(2);
            }

            .text {
                margin-top: auto;
                margin-bottom: 18px;
                @include line-clamp(3);
            }

            .proceed-button {
                width: fit-content;
            }

            .button.button.return-button::after, .button.button.proceed-button::after {
                border-left: 5px solid var(--color-grayscale-9);
            }
        }

        img {
            max-height: 275px;
            min-height: 275px;
            object-fit: cover;
            object-position: center;
        }
    }

    @media only screen and (max-width: 1450px) {
        padding-left: 1.3333333333rem;
        padding-right: 1.3333333333rem;
    }

    @media only screen and (max-width: 640px) {

        .column.title {
            padding: 0;
        }

        h2 {
            margin: 25px 0 15px 0;
            font-size: 1.3333333333rem;
        }

        .related-articles-container {
            grid-auto-flow: row;
        }

        .tile {
            margin: 0 0 25px 0;

            &:last-of-type {
                margin-bottom: 0;
            }

            .content {
                .title {
                    margin: 20px 0 7px;
                }

                .text {
                    margin-bottom: 7px;
                }

                .button.button.medium:not(.toggle-button):not(.icon-button), .button.button.large:not(.toggle-button):not(.icon-button) {
                    padding-left: 0;
                }
            }
        }
    }
}

.front-page-inspirations-container.homepage-block {
    .front-page-inspirations-container {
        background: var(--color-grayscale-10);
        padding-bottom: 0;

        @media only screen and (max-width: 640px) {
            padding-bottom: 0;
        }
    }

    .front-page-inspirations {
        @media only screen and (max-width: 1450px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
