@import '../../partials/customer-ratings';


#homepageAds {

  margin-top: $grid-column-gutter;
  .row {
    margin-bottom: $grid-column-gutter;
  }

  img {
    width: 100%;
  }
}

.homepage-block {
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(30);

    @include breakpoint(small only){
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);
    }
}

#main {
    @include breakpoint(medium up) {
        margin-bottom: rem-calc(32);
    }
}
