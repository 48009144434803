.top-5-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        .category-button {
            margin-top: rem-calc(50);
        }
    }

    .title {
        font-size: rem-calc(32);
        margin-bottom: rem-calc(15);
    }

    .tabs {
        display: flex;
        border: none;

        .tabs-title {
            line-height: rem-calc(30);

            a {
                display: inline;
                padding: rem-calc(0 14);
                font-size: inherit;
                color: inherit;

                &:hover {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }

        .is-active {
            background-color: var(--color-grayscale-2);
            color: var(--color-grayscale-9);
        }
    }

    .tabs-content > .tabs-panel.is-active {
        position: relative;
    }

    .product-card:nth-child(5) {
        display: none;
    }

    @include breakpoint(large) {
        .product-card {
            min-width: 16.4rem;
        }
    }

    @include breakpoint(medium only) {
        ul.medium-block-grid-3 {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                flex: 0 0 auto;
            }
        }
    }

    .product-list {
        margin: 0;

        @include breakpoint(medium down) {
            display: flex;

            .delivery-time--promise, .delivery-promise-addition {
                display: none;
            }

            .product-card--title {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: normal;
                height: unset;
                min-height: unset;
            }
        }
    }

    .product-list:not(.loading) {
        transition: unset;
    }

    .product-card {
        display: flex;
        flex-direction: column;

        @include breakpoint(medium down) {
            border-right: none !important;
            border-bottom: none !important;
        }
    }

    /*----- Carousel elements -----*/
    .carousel {
        &--canvas {
            position: relative;
        }
    }


    &.homepage-block {

        .tabs-content {
            width: 100%;
            border: none;
        }

        .tabs-panel {
            padding: 0;
        }

        .swiper-container {
            padding: rem-calc(16) rem-calc(16) rem-calc(24) rem-calc(16);
        }

        .swiper-pagination {
            bottom: 0;
        }

        .tabs-content > .tabs-panel.is-active {
            padding-bottom: 0;
        }

        .product-card:not(:hover) {
            margin-bottom: 0;

            .product-card--content {
                padding-bottom: 0;
            }

            .product-card--flyout-container {
                padding: 0 0 0 0.8rem;

                .product-card--delivery-info {
                    padding: rem-calc(10) 0 0 0;
                }
            }
        }
    }
}
