@use "sass:math";

$primary-foreground-color: var(--color-grayscale-9);
$primary-background-color: #ebebeb;
$center-big-play-button: true;

.video-js {
    font-size: 10px;

    color: $primary-foreground-color;
    background-color: #fff;

    .vjs-current-time {
        display: block;
    }

    &:hover {
        .vjs-big-play-button {
            background-color: #fff;
        }
    }
}

.vjs-big-play-button.vjs-big-play-button {
    font-size: 3em;

    color: $primary-foreground-color;
    background-color: #fff;

    $big-play-size: rem-calc(54);

    line-height: $big-play-size;
    height: $big-play-size;
    width: $big-play-size;

    border: 1px solid #d0d0d0;
    border-radius: 50%;

    @if $center-big-play-button {
        left: 50%;
        top: 50%;
        margin-left: -($big-play-size / 2);
        margin-top: -($big-play-size / 2);
    } @else {
        left: 0.5em;
        top: 0.5em;
    }

    &:hover {
        color: $primary-foreground-color;
    }
}

.video-js .vjs-control-bar,
.video-js .vjs-menu-button .vjs-menu-content {
    background-color: $primary-background-color;
}

.video-js .vjs-button {
    color: $primary-foreground-color;

    &:focus, &:hover {
        background-color: $primary-background-color;
        color: $primary-foreground-color;
    }
}

.video-js .vjs-poster {
    background-color: #fff;
}

// Make a slightly lighter version of the main background
// for the slider background.
$slider-bg-color: #bcbcbc;

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
    background-color: $slider-bg-color;
    background-color: rgba($slider-bg-color, 0.5);
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
    background: $primary-foreground-color;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress, .video-js .vjs-volume-bar {
    /* For IE8 we'll lighten the color */
    background: lighten($slider-bg-color, 25%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba($slider-bg-color, 0.5);
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
    /* For IE8 we'll lighten the color */
    background: lighten($slider-bg-color, 50%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba($slider-bg-color, 0.75);
}

