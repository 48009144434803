.category-slider {
    overflow: hidden;

    &-container {
        width: 100%;
    }

    h2 {
        text-align: center;
        margin-bottom: rem-calc(39);

        span {
            font-weight: bold;
        }
    }

    &--item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-image {
            background-color: var(--color-grayscale-10);
            border-radius: 50%;
            height: rem-calc(205);
            width: rem-calc(205);
            padding: rem-calc(39) rem-calc(29);
            margin-bottom: rem-calc(29);

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: auto;
                width: 100%;
                object-fit: contain;
            }
        }

        &-text {
            text-align: center;
            inline-size: rem-calc(60);
            height: 24px;
            width: 205px;
            font-size: 17px;
            font-weight: bold;
        }
    }
}

@include breakpoint(small only) {
    .category-slider {
        display: none;
    }
}

.category-slider-mobile {
    display: flex;
    justify-content: space-between;
    float: left;
    white-space: nowrap;
    width: 100%;
    overflow-y: scroll;
    column-gap: 20px;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &--item {
        display: block;
        flex-direction: column;
        align-items: center;
        width: 100px;
        height: 100px;
        justify-content: center;
        text-align: center;

        &--text {
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            text-align: center;
            padding-top: rem-calc(11);
            inline-size: rem-calc(60);
            white-space: normal;
        }

        &--image {
            height: rem-calc(48);
            min-height: rem-calc(48);
            min-width: rem-calc(52);
            max-width: rem-calc(52);
            object-fit: contain;
            margin: 0 auto;
        }
    }

    @include breakpoint(medium up) {
        &--wrapper {
            display: none;
        }

        &--item {
            &--text {
                font-size: 1rem;
                inline-size: inherit;
            }

            &--image {
                height: 3.2rem;
                min-height: 5rem;
                min-width: 5rem;
                max-width: 5rem;
                object-fit: cover;
            }
        }
    }
}
